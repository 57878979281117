.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f8f8f8;
  text-align: center;
}

.not-found-container h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.not-found-container p {
  font-size: 24px;
  margin-bottom: 20px;
}

.not-found-container a {
  font-size: 18px;
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  padding: 10px 20px;
  border-radius: 5px;
}
