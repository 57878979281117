.rotary-nav {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.rotary-nav .toggle {
  background-color: black;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 1001;
  bottom: 25px;
  right: 25px;
}

.rotary-nav .links {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}

.rotary-nav.open .links {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.rotary-nav .links a {
  background-color: black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: transform 0.3s;
}

.rotary-nav.open .links a:hover {
  background-color: #ff4800;
}

/* Background blur effect */
.overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.notification-icon {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
}

.notification-badge-closed {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  transform: translate(50%, -50%);
}
