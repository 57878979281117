/* Import Custom Font */
@font-face {
  font-family: "Mokoto";
  src: url("../assets/fonts/mokoto-mokoto-regular-400.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Default Theme */

body {
  background-color: #f0f0f0;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

h1 {
  color: #333;
  font-family: "Mokoto";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

/* input,
textarea {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
}

input::placeholder,
textarea::placeholder {
  color: #888;
} */

/* button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
} */

/* hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 20px 0;
} */

/* .container {
  padding: 20px;
}

.card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 0;
} */

/* Dark Mode */

body.dark-mode {
  /* background-color: rgb(84, 84, 84); */
  background: linear-gradient(-45deg, #2c2c2c, #333, #242f33, #404040);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  color: #fff;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* body.dark-mode h1,
body.dark-mode h2,
body.dark-mode h3,
body.dark-mode h4,
body.dark-mode h5,
body.dark-mode h6 {
  color: #262525;
} */

/* body.dark-mode input,
body.dark-mode textarea {
  background-color: #333;
  color: #ffffff;
  border: 1px solid #555;
}

body.dark-mode input::placeholder,
body.dark-mode textarea::placeholder {
  color: #c2c2c2;
} */

/* body.dark-mode button {
  background-color: #444;
  color: #fff;
}

body.dark-mode button:hover {
  background-color: #222;
}

body.dark-mode hr {
  border-top: 1px solid #555;
}

body.dark-mode .container {
  background-color: #444;
  padding: 20px;
  border-radius: 8px;
}

body.dark-mode .card {
  background-color: #555;
  border: 1px solid #777;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
} */

/* Additional Styling for Default Theme */
/* a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #f8f8f8;
}

.table-dark th,
.table-dark td {
  border: 1px solid #777;
}

body.dark-mode .table th,
body.dark-mode .table td {
  border: 1px solid #555;
}

body.dark-mode .table th {
  background-color: #666;
} */
