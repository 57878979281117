.search-bar {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.search-bar input {
  width: 100%;
  /* padding: 8px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #3a3a3a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.search-result-item {
  padding: 10px;
  cursor: pointer;
}

.search-result-item:hover {
  background: #f0f0f0;
}

/* src/styles/SearchBar.css */

.search-container {
  position: relative;
  width: 100%;
  max-width: 180px;
  z-index: 2;
}

.search-input {
  width: 100%;
  padding: 10px;
  /* font-size: 16px; */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background: #3a3a3a;
  color: #fff;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  /* border: 1px solid #ccc; */
  border-top: none;
  background-color: #3a3a3a;
  border-radius: 0 0 5px 5px;
  max-height: fit-content;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  max-width: 250px;
}

.search-dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: #fff;
}

.search-dropdown-item:hover {
  background-color: #f0f0f0;
}

.no-results {
  padding: 10px;
  color: #999;
}

@media (max-width: 768px) {
  .search-container {
    max-width: 200px;
    display: flex;
    justify-content: center;
  }

  .search-input {
    width: 60%;
    font-size: 12px;
  }
}
